import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ComputerIcon from '@material-ui/icons/Computer';
import Card from 'react-bootstrap/Card';
import { Header } from '../../components/Header';
import { Link } from 'react-router-dom';
import { Search } from '../../components/Search';
import BreadPath from '../../components/BreadPath';
import './styles.css';
import { VideosContext } from '../../contexts';
import Item2 from '../../components/Item2';
import { Modal } from '../../components/Modal';

export function Main() {
  const playlist = useContext(VideosContext);
  const [busca, setBusca] = useState('');

  const videoId = window.location.hash.slice(1);
  const width = 1200;
  const video = playlist ? playlist.data.find((v) => v.videoId === videoId) : null;
  const player = video
    ? video.player
        .replace(/width="[^"]+"/, `width="${width}"`)
        .replace(/height="[^"]+"/, `height="${(width * 270) / 480}"`)
    : '';

  setTimeout(() => {
    const anchor = video ? document.querySelector(`#anchor-${videoId}`) : null;
    const top = anchor ? document.documentElement.scrollTop + anchor.getBoundingClientRect().top - 20 : null;

    if (top && document.documentElement.scrollTop !== top) {
      document.documentElement.scrollTop = top;
    }
  }, 1000);

  return (
    <>
      <Header />
      <Container>
        <Search busca={busca} setBusca={setBusca}></Search>
        <BreadPath pathName="Página inicial" currentLink="/"></BreadPath>
        <Row>
          <Col md={4}>
            <Link to="/videos/app/" className="link">
              <Card>
                <Card.Body>
                  <Card.Title className="icon">
                    <PhoneIphoneIcon style={{ fontSize: 130 }}></PhoneIphoneIcon>
                  </Card.Title>
                  <Card.Text className="titulo-categoria">Tutoriais Aplicativos</Card.Text>
                  <Card.Subtitle className="subtitulo-categoria">
                    Clique aqui para acessar os vídeos de app
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/videos/web/" className="link">
              <Card>
                <Card.Body>
                  <Card.Title className="icon">
                    <ComputerIcon style={{ fontSize: 130 }}></ComputerIcon>
                  </Card.Title>
                  <Card.Text className="titulo-categoria">Tutoriais Web</Card.Text>
                  <Card.Subtitle className="subtitulo-categoria">
                    Clique aqui para acessar os vídeos de web
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/videos/treinamento/" className="link">
              <Card>
                <Card.Body>
                  <Card.Title className="icon">
                    <ComputerIcon style={{ fontSize: 130 }}></ComputerIcon>
                  </Card.Title>
                  <Card.Text className="titulo-categoria">Treinamentos</Card.Text>
                  <Card.Subtitle className="subtitulo-categoria">
                    Clique aqui para acessar os vídeos de treinamento
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
      {busca ? (
        <Container style={{ marginTop: 10 }}>
          <Row>
            <Col md={4} style={{ padding: 20 }}>
              {playlist?.data
                ?.filter(
                  (video) =>
                    video.playlist.toLowerCase() === 'app' &&
                    video.title
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()
                      .includes(
                        busca
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                      )
                )
                .map((video) => {
                  return <Item2 key={video.videoId} video={video} categoria={'app'}></Item2>;
                })}
            </Col>
            <Col md={4} style={{ padding: 20 }}>
              {playlist?.data
                .filter(
                  (video) =>
                    video.playlist.toLowerCase() === 'web' &&
                    video.title
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()
                      .includes(
                        busca
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                      )
                )
                .map((video) => {
                  return <Item2 key={video.videoId} video={video} categoria={'web'}></Item2>;
                })}
            </Col>
            <Col md={4} style={{ padding: 20 }}>
              {playlist?.data
                .filter(
                  (video) =>
                    video.playlist.toLowerCase() === 'treinamento' &&
                    video.title
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .toLowerCase()
                      .includes(
                        busca
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                      )
                )
                .map((video) => {
                  return <Item2 key={video.videoId} video={video} categoria={'treinamento'}></Item2>;
                })}
            </Col>
          </Row>
        </Container>
      ) : (
        ''
      )}
      {video && (
        <Modal>
          <div dangerouslySetInnerHTML={{ __html: player }}></div>
        </Modal>
      )}
    </>
  );
}
