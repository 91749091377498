import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export function Search({ busca, setBusca }) {
  return (
    <Form.Group className="mt-2" controlId="formBasicEmail">
      <Row>
        <Col md={10}>
          <Form.Control
            type="text"
            value={busca}
            placeholder="Busque sua resposta..."
            onChange={(e) => setBusca(e.target.value || '')}
          />
        </Col>
        <Col md={2}>
          <Button variant="dark" type="button" block>
            Pesquisar
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );
}
