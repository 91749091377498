import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.css';

const Item = ({ video, categoria }) => {
  const history = useHistory();

  const redirect = useCallback(() => {
    const anchor = document.querySelector(`#anchor-${video.videoId}`);
    const top = anchor ? document.documentElement.scrollTop + anchor.getBoundingClientRect().top - 20 : null;

    if (top) {
      document.documentElement.scrollTop = document.documentElement.scrollTop + top - 20;
    }

    history.push(`/videos/${categoria}#${video.videoId}`);
  }, [video, categoria, history]);

  return (
    <Row className="mb-2">
      <Col sm={12} md={4} lg={1} xl={1}>
        <button className="link" onClick={() => redirect()}>
          <img src={`https://img.youtube.com/vi/${video.videoId}/0.jpg`} alt={video.title} height="200" />
        </button>
      </Col>
      <Col sm={0} md={1} lg={3} xl={2}></Col>
      <Col sm={12} md={7} lg={8} xl={9}>
        <button className="link" onClick={() => redirect()}>
          {/* eslint-disable-next-line */}
          <a id={`anchor-${video.videoId}`} className="titulo-video">
            {video.title}
          </a>
        </button>
        <pre className="texto-video">{video.description}</pre>
      </Col>
    </Row>
  );
};

export default Item;
