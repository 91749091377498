import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoSigma from '../../assets/logo_sigma_login.png';
import { Link } from 'react-router-dom';
import './styles.css';
import { VideosContext } from '../../contexts';
import { format } from 'date-fns';

export function Header() {
  const playlist = useContext(VideosContext);

  return (
    <Container fluid>
      <Row className="topo">
        <Col md={{ span: 1, offset: 1 }}>
          <Link to="/">
            <img className="mt-3" src={logoSigma} alt="Logo Sigma" width="300" />
          </Link>
        </Col>
      </Row>
      <Row style={{ backgroundColor: '#282842' }}>
        <Col md={{ span: 5, offset: 1 }} style={{ color: 'gray', fontStyle: 'italic', fontWeight: 300 }}>
          Lista de vídeos atualizada em:{' '}
          {playlist?.updated_at && format(new Date(playlist.updated_at), "dd'/'MM'/'uuuu' 'HH':'mm")}
        </Col>
      </Row>
    </Container>
  );
}
