import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Item2 = ({ video, categoria }) => {
  return (
    <Row className="mb-2">
      <Col sm={12} md={4} lg={1} xl={1}>
        <a id={`anchor-${video.videoId}`} className="titulo-video" href={`#${video.videoId}`}>
          <img src={`https://img.youtube.com/vi/${video.videoId}/0.jpg`} alt={video.title} height="70" />
        </a>
      </Col>
      <Col sm={0} md={1} lg={3} xl={2}></Col>
      <Col sm={12} md={7} lg={8} xl={9}>
        <a
          id={`anchor-${video.videoId}`}
          className="titulo-video"
          href={`#${video.videoId}`}
          style={{ fontSize: 12, padding: 10, display: 'inline-block' }}
        >
          {video.title}
        </a>
      </Col>
    </Row>
  );
};

export default Item2;
