import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { VideosProvider } from './contexts';
import { Main } from './pages/Main/index';
import { Videos } from './pages/Videos';

import md5 from 'md5';

const path =
  'https://script.google.com/macros/s/AKfycbxC-RIwao_6qHnTZz4VYNeYcgIxCzD_72mO3fdHRnFw_Qr1bj1f2Pvnd3IxGT6iOjvC/exec';

const Routes = () => {
  const [playlist, setPlaylist] = useState(JSON.parse(localStorage.getItem('videos') || 'null'));

  function handleNocache() {
    const senha = prompt('Digite a senha:');
    if (senha && md5(senha) === 'da2ddabe55557f56409fd70650a0ee81') {
      axios(`${path}?nocache`)
        .then((response) => {
          setPlaylist(response.data);
        })
        .catch(() => {
          alert(`A cota foi excedida, tente novamente mais tarde`);
        });
    }
  }

  useEffect(() => {
    if (!playlist?.updated_at || +new Date() - +new Date(playlist.updated_at) > 3600000) {
      axios(path)
        .then((response) => {
          localStorage.setItem('videos', JSON.stringify(response.data));
          setPlaylist(response.data);
        })
        .catch((e) => {
          console.error('Erro ao atualizar lista de vídeos', e);
        });
    }
  }, []); // eslint-disable-line

  return (
    <VideosProvider value={playlist}>
      <BrowserRouter>
        <div
          style={{
            height: playlist !== null ? '0px' : '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'height 1s',
            overflow: 'hidden'
          }}
        >
          Carregando lista de videos...
        </div>
        <Switch>
          <Route exact path="/" component={Main}></Route>
          <Route path="/videos/:categoria" component={Videos}></Route>
          <Route path="/videos" component={Videos}></Route>
        </Switch>
        <button
          onClick={() => handleNocache()}
          style={{
            width: 50,
            height: 50,
            display: 'block',
            background: 'none',
            border: 'none',
            position: 'fixed',
            bottom: 10,
            right: 10,
            zIndex: 1
          }}
        >
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 2v11h3v9l7-12h-4l4-8z" fill="rgba(0, 0, 0, 0.25)"></path>
          </svg>
        </button>
      </BrowserRouter>
    </VideosProvider>
  );
};
export default Routes;
