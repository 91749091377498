import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';

export const Modal = ({ children, categoria }) => {
  const history = useHistory();

  return (
    <div className="mdl">
      <div
        className="mdl-backdrop"
        onClick={() => (categoria ? history.push(`/videos/${categoria}`) : history.push('/'))}
      ></div>
      <div className="mdl-content">{children}</div>
    </div>
  );
};
