import React from 'react';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import './styles.css';

const BreadPath = (props) => {
  let backButton;
  if (props.back) {
    backButton = (
      <h2>
        <Link className="header-caminho" to={props.linkBack}>
          Página inicial /
        </Link>
      </h2>
    );
  }
  return (
    <Row className="row-header mb-2">
      {backButton}
      <h2>
        <Link className="header-caminho" to={props.currentLink}>
          {props.pathName}
        </Link>
      </h2>
    </Row>
  );
};

export default BreadPath;
