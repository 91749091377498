import React, { useContext, useState } from 'react';
import { Header } from '../../components/Header';
import Container from 'react-bootstrap/Container';
import { Search } from '../../components/Search';
import Item from '../../components/Item';
import BreadPath from '../../components/BreadPath';

import './styles.css';
import { VideosContext } from '../../contexts';
import { Modal } from '../../components/Modal';

export function Videos(props) {
  const playlist = useContext(VideosContext);
  const { categoria } = props.match.params;
  const [busca, setBusca] = useState('');
  const caminho =
    categoria === 'app'
      ? ' Vídeos App '
      : categoria === 'web'
      ? ' Vídeos Web '
      : categoria === 'treinamento'
      ? ' Vídeos Treinamentos '
      : '-';

  const videoId = window.location.hash.slice(1);
  const width = 1200;
  const video = playlist ? playlist.data.find((v) => v.videoId === videoId) : null;
  const player = video
    ? video.player
        .replace(/width="[^"]+"/, `width="${width}"`)
        .replace(/height="[^"]+"/, `height="${(width * 270) / 480}"`)
    : '';

  setTimeout(() => {
    const anchor = video ? document.querySelector(`#anchor-${videoId}`) : null;
    const top = anchor ? document.documentElement.scrollTop + anchor.getBoundingClientRect().top - 20 : null;

    if (top && document.documentElement.scrollTop !== top) {
      document.documentElement.scrollTop = top;
    }
  }, 1000);

  return (
    <>
      <Header />

      <Container>
        <Search busca={busca} setBusca={setBusca}></Search>
        <BreadPath
          back={true}
          linkBack="/"
          pathName={caminho}
          currentLink={categoria ? `/videos/${categoria}` : '/'}
        ></BreadPath>
      </Container>
      <Container>
        {playlist?.data
          ?.filter(
            (video) =>
              video.playlist.toLowerCase() === categoria &&
              video.title
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .includes(
                  busca
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                )
          )
          .map((video) => {
            return <Item key={video.videoId} video={video} categoria={categoria}></Item>;
          })}
      </Container>

      {video && (
        <Modal categoria={categoria}>
          <div dangerouslySetInnerHTML={{ __html: player }}></div>
        </Modal>
      )}
    </>
  );
}
